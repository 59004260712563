function getFloatValue(value) {
  let result = parseFloat(value);
  return isNaN(result) ? 0 : result;
}

// Define a custom sorter here which parses numbers if it can.
// If we don't do this, AG Grid will default to sorting alphabetically
// instead of numerically.
export const numberParsingSorter = (a, b) => getFloatValue(a) - getFloatValue(b);

export const formatNumberOrDefault = (value, precision, defaultValue) => {
  // Explicitly check for undefined or null,
  // as falsy values can be valid
  if (value === undefined || value === null || typeof value !== 'number') {
    return defaultValue;
  }

  return value.toFixed(precision);
};

// TODO: Consider right place to put below code

// 52 Week Logic
const new52WeekHigh = (quote) => {
  let high52Week = quote.high52Week;
  let high = quote.high;
  return high52Week > 0 && high >= high52Week;
};

const new52WeekLow = (quote) => {
  let low52Week = quote.low52Week;
  let low = quote.low;
  return low52Week > 0 && low > 0 && low <= low52Week;
};

const hitMaxPrice = (quote) => {
  let maxPrice = quote.maxPrice;
  let high = quote.high;
  return maxPrice > 0 && high >= maxPrice;
};

const hitMinPrice = (quote) => {
  let minPrice = quote.minPrice;
  let low = quote.low;
  return minPrice > 0 && low > 0 && low <= minPrice;
};

export const get52WeekHighFlag = (props, localize) => {
  if (!props.quoteData) {
    return null;
  }

  if (hitMaxPrice(props.quoteData)) {
    return localize('marketboard.row.flag.stophigh');
  }
  if (new52WeekHigh(props.quoteData)) {
    return localize('marketboard.row.flag.newhigh');
  }
  return null;
};

export const get52WeekLowFlag = (props, localize) => {
  if (!props.quoteData) {
    return null;
  }

  if (hitMinPrice(props.quoteData)) {
    return localize('marketboard.row.flag.stoplow');
  }
  if (new52WeekLow(props.quoteData)) {
    return localize('marketboard.row.flag.newlow');
  }
  return null;
};

// Bid/Ask logic
export const getBidTypeFlag = (props) => {
  if (!props.quoteData) {
    return null;
  }

  return getQuoteFlag(props.quoteData.bidType);
};

export const getAskTypeFlag = (props) => {
  if (!props.quoteData) {
    return null;
  }

  return getQuoteFlag(props.quoteData.askType);
};

export const getQuoteFlag = (flag) => {
  switch (flag) {
    case 'C': {
      return '連';
    }
    case 'S': {
      return '特';
    }
    case 'P': {
      return '前';
    }
    case 'R': {
      return '・';
    }
    default: {
      return null;
    }
  }
};
